import React from 'react'
import Wrapper from './Wrapper'

export default class FormInput extends Wrapper {
	render() {
		const { classElement, fieldApi,  onChange, onBlur, label, className,  value, ...rest } = this.props

		const { setValue, setTouched } = fieldApi


		return (
			<div className={this.getWrapperClass()}>
				{this.props.label ? (
					<label htmlFor={this.props.id} className={this.getLabelClass()}>
						{this.props.label}
					</label>
				) : (
					''
				)}
				<input
					className={this.getElementClass()}
					value={fieldApi.value || ''}

					onChange={e => {
						setValue(e.target.value)
						if (onChange) {
							onChange(e.target.value, e)
						}
					}}
					onBlur={e => {
						setTouched()
						if (onBlur) {
							onBlur(e)
						}
					}}
					{...rest}
				/>
				<span className="bar"></span>
				{this.renderMessage()}
			</div>
		)
	}
}
