import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals'
import 'isomorphic-fetch'

import Root from './Root'
import configureStore from './store/configureStore'

const store = configureStore()

const root = document.getElementById('root')

const render = () => {
	if (!root) return

	ReactDOM.render(
		<Provider store={store}>
			<Root />
		</Provider>,
		root
	)
}


// root.render(
// 		<Provider store={store}>
// 			<Root />
// 		</Provider>
// 	)

// store.subscribe(render)
render()

reportWebVitals()
