import React, { useCallback, useRef } from 'react'
import Recaptcha from 'react-google-invisible-recaptcha'
import {Form} from 'react-form'

function CaptchaForm({onSubmit, children, siteKey}) {
	const recaptcha = useRef()

	const onSubmitRecaptch = useCallback(async (v) => {
		recaptcha.current.execute()
		// Solves a race condition between recaptcha and setState
		await new Promise(resolve => setTimeout(resolve, 600))
		await onSubmit({...v, 'g-recaptcha-response': recaptcha.current.getResponse() })
	}, [])

	return (
		<Form onSubmit={onSubmitRecaptch}>
			{ formApi => {
				var childrenWithProps = React.Children.map(children, child =>
					React.cloneElement(child, { ...formApi }))
				return(
					<div>
						{childrenWithProps}
						<Recaptcha
							ref={ref => recaptcha.current = ref}
							sitekey={siteKey}
						/>
					</div>
				)
			}}
		</Form>
	)
}

export default CaptchaForm
