import { combineReducers } from 'redux'

const userdata = (state = {}, action) => {
	switch(action.type){
		case 'SET_SESSION_DATA':
			return action.userdata
		default:
			return state
	}
}

const userId = (state = null, action) => {
	switch(action.type){
		case 'SET_SESSION_DATA':
			return action.userdata.user_id
		default:
			return state
	}
}

export default combineReducers({
	userdata,
	userId
})


export const getIsLoggedIn = (state) => state.userId ? true : false
export const getSessionUserId = (state) => state.userId
export const getSessionUserdata = (state) => state.userId
