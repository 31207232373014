/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { hot } from 'react-hot-loader'
import ImageGallery from 'react-image-gallery'
import Modal from 'react-responsive-modal'
import NumberFormat from 'react-number-format'
import ReactPlayer from 'react-player'
import MetaTags from 'react-meta-tags'

import Loading from './Loading'
import Config from '../config'
import { CaptchaForm } from './Form'
import FormContact from './FormContact'
import FormShare from './FormShare'

import {
	FacebookShareButton,
	GooglePlusShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	FacebookIcon,
	TwitterIcon,
	GooglePlusIcon,
	LinkedinIcon,
} from 'react-share'

import defaultImage from '../assets/images/jkgl_wbg.png'


import { TrackGoogleTag } from '@customd/cd-analytics-tracker'

import ReactGA from 'react-ga4'
ReactGA.initialize(Config.googleAnalyticsCode)

import 'react-image-gallery/styles/css/image-gallery.css'

import axios from '../api/axios'

import jkgl_wbg from '../assets/images/jkgl_wbg.png'

function Details({ match }) {
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [listing, setListing] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formError, setFormError] = useState(false)
  const [shareFormSubmitted, setShareFormSubmitted] = useState(false)
  const [shareFormError, setShareFormError] = useState(false)
	const [step] = useState(Number(new Date()))
	const imageGalleryRef = useRef()

  useEffect(() => {
    const init = async () => {
      const { params } = match
      // this.clickTimeout = null
      window.scrollTo(0, 0)
      try {
        const res = await axios.get(`/property/${params.strUID}`)
        setListing(res.data.data)
      } catch (error) {
        console.log('err', error)
      } finally {
        setLoading(false)
      }
    }
	  init()
  }, [match])


  const onOpenModal = useCallback(() => setModalOpen(true), [])
  const onCloseModal = useCallback(() => setModalOpen(false), [])

  	const handleEvent = useCallback((action, category = 'Visitor') => {
		ReactGA.event({
			category,
			action,
		})
	}, [])

    const handleImageError =  useCallback((event) => {
    		event.target.src = defaultImage
    }, [])

	const handleContactSubmit = useCallback(async (values) => {

		values.message = values.em
		values.type = 'Property Enquiry'
		values.subscribe = values.signup

		values.message += '\n\n' + listing.tenancy_id
		values.message += '\n' + listing.name
		values.message += '\n' + listing.street

		if (values.signup === true) {
			values.message += '\n\n' + ' Newsletter Subcription enabled'
		}

		setFormError(false)

		try {
			await axios.post('/contact', values)
			handleEvent('Contact Form Submitted', 'Contact')
			TrackGoogleTag('event', 'conversion', {
				send_to: 'AW-833782124/O16TCJCk2KYBEOyCyo0D',
			})
		} catch (error) {
			setFormSubmitted(false)
			setFormError(error.errors)
		}
	}, [handleEvent, listing])

    const handleClick = useCallback(() => {
    	// if (this.clickTimeout !== null) {
    	// 	if (this._imageGallery.state.isFullscreen) {
    	// 		this._imageGallery.exitFullScreen()
    	// 	} else {
    	// 		this._imageGallery.fullScreen()
    	// 	}
    	// 	clearTimeout(this.clickTimeout)
    	// 	this.clickTimeout = null
    	// } else {
    	// 	this.clickTimeout = setTimeout(() => {
    	// 		clearTimeout(this.clickTimeout)
    	// 		this.clickTimeout = null
    	// 	}, 500)
    	// }
    }, [])

	const handleShareSubmit = useCallback(async (values) => {
		try {
			setShareFormError(false)
			await axios.post(`/property/${listing.tenancy_id}`, values)
			setShareFormSubmitted(true)
		} catch (error) {
			setShareFormSubmitted(false)
			setShareFormError(error.errors)
		}
	}, [listing])

	useEffect(() => {
		if (shareFormSubmitted) {
			setTimeout(() => setShareFormSubmitted(false), 5000)
		}
	}, [shareFormSubmitted])

  const url = window.location.href

  if (loading) {
    return <Loading />
  }


  const shareErrorDisplay = shareFormError ? (
	  <div className="form__error">
  		{Object.values(shareFormError).map((err, idx) => (
  			<span key={idx}>{err.join(' ')}</span>
  		))}
  	</div>
  ) : (
  	''
  )

  if (!listing) {
    return (
      <section className={'row'}>
        <div className="welcome__wrapper content-block">
          <h1 className="welcome__header">404 NOT FOUND</h1>
          <hr className="divider" />
          <h2 className="welcome__subheader">
            Please check the URL you have entered. Alternatively use the search box or browse using the site menu.
          </h2>
        </div>
      </section>
    )
  }

  const { googleMapsKey } = Config
  const ContacteMail = listing.Contact_eMail === '' ? 'james@jkgl.co.nz' : listing.Contact_eMail

  const images = listing.image
    ? [
        {
          original: `${listing.image}?width=1600&height=1200`,
          thumbnail: `${listing.image}?width=150&height=100`,
        },
      ]
    : []
  let imageidx = 2
  let imagekey = ''
  let imageActiveKey = ''
  for (imageidx = 2; imageidx <= 9; imageidx++) {
    imagekey = `SuperContainer_Link_${imageidx}_RawData`
    imageActiveKey = `Image_Available_${imageidx}`
    if (listing[imageActiveKey] === 'Yes') {
      images.push({
        original: `${listing[imagekey]}?width=1600&height=1200`,
        thumbnail: `${listing[imagekey]}?width=150&height=100`,
      })
    }
  }

  images.push({
    original: jkgl_wbg,
    thumbnail: jkgl_wbg,
  })

  return (
    <div className="tenancy content-block content-block-padded">
      <MetaTags>
        <title>
          {listing.Tenancies_Property} | {listing.type} | James Kirkpatrick Group Limited
        </title>
      </MetaTags>

      <Modal open={modalOpen} onClose={onCloseModal} little>
        <div className="social_share__container share_modal">
          <div className="share_modal__header">
            <h4 className="tenancy__heading share_modal__heading">SHARE LISTING VIA EMAIL</h4>
            <p className="share_modal__heading">
              To share this listing with someone, simply fill in the details below and we will do the rest for you.
            </p>
          </div>

          <div className="share_modal__form-wrapper">
		  	{shareErrorDisplay}

            {shareFormSubmitted ? (
              <div className="form-success">
                Thank you for your interest{' '}
                <span className="icon__wrapper">
                  <i className="material-icons">done</i>
                </span>
              </div>
            ) : (
              <CaptchaForm siteKey={Config.googleRecaptchSiteKey} onSubmit={handleShareSubmit}>
                <FormShare />
              </CaptchaForm>
            )}
          </div>
        </div>
      </Modal>

      <div className="mdl-grid">
        <div className="section--left mdl-cell mdl-cell--8-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">
          <div className="section__sidebar--panel clearfix">
            <div className="tenancy__info">
              <div className="section--icon">
                <i className="material-icons">location_on</i>
              </div>

              <h3 className="tenancy__detail--heading">
                <span className="tenancy__detail--street">{`${listing.Tenancies_Address_Street}`}</span> <br />
                <span className="tenancy__detail--suburb">
                  {`${listing.suburb}, `}
                  {`${listing.Tenancies_Address_City}`}
                </span>
              </h3>
            </div>

            <ImageGallery
              ref={imageGalleryRef}
              showPlayButton={true}
              items={images}
              onImageError={handleImageError}
              onThumbnailError={handleImageError}
              autoPlay={false}
              showFullscreenButton
              onClick={handleClick}
            />

            <div className="tenancy__feature">
              <div className="tenancy__feature--row">
                <p className="tenancy__feature--item">Tenancy:</p>
                <p className="tenancy__feature--item">{listing.tenancy}</p>
              </div>

              <div className="tenancy__feature--row">
                <p className="tenancy__feature--item">Type:</p>
                <p className="tenancy__feature--item">{listing.type}</p>
              </div>

              {listing.tenancies_car_parks_quantity ? (
                <div className="tenancy__feature--row">
                  <p className="tenancy__feature--item">Carparks:</p>
                  <p className="tenancy__feature--item">{Math.round(listing.tenancies_car_parks_quantity)}</p>
                </div>
              ) : (
                ''
              )}

              {listing.tenancies_area_canopy_m2 ? (
                <div className="tenancy__feature--row">
                  <p className="tenancy__feature--item">Canopy (sqm):</p>
                  <p className="tenancy__feature--item">
                    <NumberFormat value={Math.round(listing.tenancies_area_canopy_m2)} displayType={'text'} thousandSeparator={true} />
                  </p>
                </div>
              ) : (
                ''
              )}

              {listing.tenancies_area_retail_m2 ? (
                <div className="tenancy__feature--row">
                  <p className="tenancy__feature--item">Retail (sqm):</p>
                  <p className="tenancy__feature--item">
                    <NumberFormat value={Math.round(listing.tenancies_area_retail_m2)} displayType={'text'} thousandSeparator={true} />
                  </p>
                </div>
              ) : (
                ''
              )}

              {listing.tenancies_area_office_m2 ? (
                <div className="tenancy__feature--row">
                  <p className="tenancy__feature--item">Office (sqm):</p>
                  <p className="tenancy__feature--item">
                    <NumberFormat value={Math.round(listing.tenancies_area_office_m2)} displayType={'text'} thousandSeparator={true} />
                  </p>
                </div>
              ) : (
                ''
              )}

              {listing.tenancies_area_warehouse_m2 ? (
                <div className="tenancy__feature--row">
                  <p className="tenancy__feature--item">Warehouse (sqm):</p>
                  <p className="tenancy__feature--item">
                    <NumberFormat value={Math.round(listing.tenancies_area_warehouse_m2)} displayType={'text'} thousandSeparator={true} />
                  </p>
                </div>
              ) : (
                ''
              )}

              {listing.tenancies_area_yard_m2 ? (
                <div className="tenancy__feature--row">
                  <p className="tenancy__feature--item">Yard (sqm):</p>
                  <p className="tenancy__feature--item">
                    <NumberFormat value={Math.round(listing.tenancies_area_yard_m2)} displayType={'text'} thousandSeparator={true} />
                  </p>
                </div>
              ) : (
                ''
              )}

              {listing.area ? (
                <div className="tenancy__feature--row">
                  <p className="tenancy__feature--item">Area (sqm):</p>
                  <p className="tenancy__feature--item">
                    <NumberFormat value={Math.round(listing.area)} displayType={'text'} thousandSeparator={true} />
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>

            <p className="tenancy__description">{listing.Tenancies_Description}</p>

            {listing.Video_Vimeo_URL ? (
              <div className="tenancy__video video --light-border ">
                <ReactPlayer className="react-player" url={listing.Video_Vimeo_URL} width="100%" height="100%" />
              </div>
            ) : (
              ''
            )}

            {listing.Livefeed_URL.trim().length > 0 && <div className="livefeed"><img src={`${listing.Livefeed_URL}&step=${step}`} /></div>}

            <button
              className="sml_button button form__button mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect"
              onClick={() => {
                window.print()
              }}
            >
              <i className="material-icons">&#xE8AD;</i> Print
            </button>
          </div>
        </div>

        <div className="section--right mdl-cell mdl-cell--4-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">
          <div className="contact_main">
            <h4>
              <a onClick={() => handleEvent('Clicked to Call Main number', 'Contact')} href="tel:093797288">
                CALL US (09) 379 7288
              </a>
            </h4>
          </div>

          <div className="section__sidebar--panel">
            <div className="tenancy__info-wrapper">
              {listing.Tenancies_Contact_Name ? (
                <p className="tenancy__detail tenancy__detail--name">{listing.Tenancies_Contact_Name}</p>
              ) : (
                ''
              )}

              {listing.Tenancies_Contact_Number ? (
                <p className="tenancy__detail tenancy__detail--item">
                  <span className="tenancy__detail--title">
                    <i className="material-icons">smartphone</i>
                  </span>
                  <a
                    className="contact__link"
                    onClick={() => handleEvent(`Clicked to Call ${listing.Tenancies_Contact_Name}`, 'Contact')}
                    href={`tel:${listing.Tenancies_Contact_Number}`}
                  >
                    {listing.Tenancies_Contact_Number}
                  </a>
                </p>
              ) : (
                ''
              )}

              <p className="tenancy__detail tenancy__detail--item">
                <span className="tenancy__detail--title">
                  <i className="material-icons">email</i>
                </span>
                <a
                  onClick={() => handleEvent(`Clicked to Email ${listing.Tenancies_Contact_Name}`, 'Contact')}
                  href={`mailto:${ContacteMail}`}
                  className="contact__link"
                >
                  {ContacteMail}
                </a>
              </p>
            </div>

            <div className="tenancy__share share_modal">
              <div className="share_border">
                <div className="share_wrapper">
                  <i className="material-icons EmailShareButton" onClick={onOpenModal}>
                    email
                  </i>
                  <FacebookShareButton onClick={() => handleEvent('Clicked to Share to Facebook', 'Contact')} url={url}>
                    {' '}
                    <FacebookIcon size={36} />
                  </FacebookShareButton>
                  <TwitterShareButton onClick={() => handleEvent('Clicked to Share to Twitter', 'Contact')} url={url}>
                    {' '}
                    <TwitterIcon size={36} />
                  </TwitterShareButton>
                  <GooglePlusShareButton onClick={() => handleEvent('Clicked to Share to Google+', 'Contact')} url={url}>
                    {' '}
                    <GooglePlusIcon size={36} />
                  </GooglePlusShareButton>
                  <LinkedinShareButton onClick={() => handleEvent('Clicked to Share to LinkedIn', 'Contact')} url={url}>
                    {' '}
                    <LinkedinIcon size={36} />
                  </LinkedinShareButton>
                </div>
              </div>
            </div>
          </div>

          <div className="section__sidebar--panel">
            <div className="tenancy__info">
              <div className="section--icon">
                <i className="material-icons">attach_money</i>
              </div>
              <h4 className="tenancy__heading">NET RENT</h4>
            </div>

            {listing.POA_Price_On_Enquiry != 'POA' ? (
              <div>
                <div className="tenancy__feature--row">
                  <p className="tenancy__feature--netrent tenancy__feature--item">Per week:</p>
                  <p className="tenancy__feature--netrent tenancy__feature--item">
                    <NumberFormat
                      value={Math.round(listing.Tenancies_Rent_per_week_Net)}
                      displayType={'text'}
                      prefix="$"
                      thousandSeparator={true}
                    />
                  </p>
                </div>

                <div className="tenancy__feature--row">
                  <p className="tenancy__feature--netrent tenancy__feature--item">Per year:</p>
                  <p className="tenancy__feature--netrent tenancy__feature--item">
                    <NumberFormat
                      value={Math.round(listing.Tenancies_Rent_per_year_net)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                    <br />
                    <span className="tenancy--netrent tenancy--fineprint">plus GST and OPEX</span>
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <div className="tenancy__feature--row">
                  <p className="tenancy__feature--netrent tenancy__feature--item">Per week:</p>
                  <p className="tenancy__feature--netrent tenancy__feature--item">POA</p>
                </div>

                <div className="tenancy__feature--row">
                  <p className="tenancy__feature--netrent tenancy__feature--item">Per year:</p>
                  <p className="tenancy__feature--netrent tenancy__feature--item tenancy--fineprint">
                    POA
                    <br />
                    <span className="tenancy--netrent tenancy--fineprint">plus GST and OPEX</span>
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="section__sidebar--panel clearfix">
            <div className="tenancy__map">
              <iframe
                width="100%"
                height="320"
                frameBorder="0"
                style={{ border: 0 }}
                src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsKey}&q=${listing.google_address}`}
                allowFullScreen
              ></iframe>
            </div>

            <div className="tenancy__enquiry">
              <h4 className="section__title">MAKE AN ENQUIRY</h4>

              {formSubmitted ? (
                <div className="form-success">
                  Thank you for your interest{' '}
                  <span className="icon__wrapper">
                    <i className="material-icons">done</i>
                  </span>
                </div>
              ) : (
                <CaptchaForm siteKey={Config.googleRecaptchSiteKey} onSubmit={handleContactSubmit}>
                  <FormContact errors={formError || {}} />
                </CaptchaForm>
              )}
            </div>
          </div>

          <div className="section__sidebar--panel">
            <div className="tenancy__contact">
              <div className="tenancy__contact-block">
                <div className="tenancy__info-wrapper">
                  <p className="tenancy__detail tenancy__detail--name">
                    <strong>{listing.ContactStaff}</strong>
                  </p>

                  {listing.ContactMobile ? (
                    <p className="tenancy__detail tenancy__detail--item">
                      <span className="tenancy__detail--title">
                        <i className="material-icons">smartphone</i>
                      </span>
                      <span>
                        <a
                          onClick={() => handleEvent(`Clicked to Call ${listing.ContactStaff}`, 'Contact')}
                          href={`tel:${listing.ContactMobile}`}
                          className="contact__link"
                        >
                          {listing.ContactMobile}
                        </a>
                      </span>
                    </p>
                  ) : (
                    ''
                  )}

                  {listing.Contact_Phone ? (
                    <p className="tenancy__detail tenancy__detail--item">
                      <span className="tenancy__detail--title">
                        <i className="material-icons">phone</i>
                      </span>
                      <span>
                        <a
                          onClick={() => handleEvent(`Clicked to Call ${listing.ContactStaff}`, 'Contact')}
                          href={`tel:${listing.Contact_Phone}`}
                          className="contact__link"
                        >
                          {listing.Contact_Phone}
                        </a>
                      </span>
                    </p>
                  ) : (
                    ''
                  )}

                  {listing.Contact_eMail ? (
                    <p className="tenancy__detail tenancy__detail--item">
                      <span className="tenancy__detail--title">
                        <i className="material-icons">email</i>
                      </span>
                      <a
                        onClick={() => handleEvent(`Clicked to Email ${listing.ContactStaff}`, 'Contact')}
                        className="contact__link"
                        href={`mailto:${listing.Contact_eMail}`}
                      >
                        {listing.Contact_eMail}
                      </a>
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {listing.ContactStaff_2 && (listing.Contact_eMail_2 || listing.ContactMobile_2 || listing.Contact_Phone_2) ? (
                <div className="tenancy__contact-block">
                  <div className="tenancy__info-wrapper">
                    <p className="tenancy__detail tenancy__detail--name">{listing.ContactStaff_2}</p>

                    {listing.ContactMobile_2 ? (
                      <p className="tenancy__detail tenancy__detail--item">
                        <span className="tenancy__detail--title">
                          <i className="material-icons">smartphone</i>
                        </span>
                        <span>
                          <a
                            onClick={() => handleEvent(`Clicked to Call ${listing.ContactStaff_2}`, 'Contact')}
                            href={`tel:${listing.ContactMobile_2}`}
                            className="contact__link"
                          >
                            {listing.ContactMobile_2}
                          </a>
                        </span>
                      </p>
                    ) : (
                      ''
                    )}

                    {listing.Contact_Phone_2 ? (
                      <p className="tenancy__detail tenancy__detail--item">
                        <span className="tenancy__detail--title">
                          <i className="material-icons">phone</i>
                        </span>
                        <span>
                          <a
                            onClick={() => handleEvent(`Clicked to Call ${listing.ContactStaff_2}`, 'Contact')}
                            href={`tel:${listing.Contact_Phone_2}`}
                            className="contact__link"
                          >
                            {listing.Contact_Phone_2}
                          </a>
                        </span>
                      </p>
                    ) : (
                      ''
                    )}

                    {listing.Contact_eMail_2 ? (
                      <p className="tenancy__detail tenancy__detail--item">
                        <span className="tenancy__detail--title">
                          <i className="material-icons">email</i>
                        </span>
                        <span>
                          <a
                            onClick={() => handleEvent(`Clicked to Email ${listing.ContactStaff_2}`, 'Contact')}
                            className="contact__link"
                            href={`mailto:${listing.Contact_eMail_2}`}
                          >
                            {listing.Contact_eMail_2}
                          </a>
                        </span>
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default hot(module)(Details)
