import React from 'react'
import {Route} from 'react-router-dom'

import ReactGA from 'react-ga4'
import Config from '../config'



class Analytics extends React.Component {
	componentDidMount() {
		console.log(Config.googleAnalyticsCode)
		ReactGA.initialize(Config.googleAnalyticsCode)
		this.sendPageChange(this.props.location.pathname, this.props.location.search)
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname || this.props.location.search !== prevProps.location.search) {
			this.sendPageChange(this.props.location.pathname, this.props.location.search)
		}
	}

	sendPageChange(pathname, search = '') {
		const page = pathname + search
		// ReactGA.set({page});
		ReactGA.send({ hitType: 'pageview', page: page })
	}

	render() {
		return null
	}
}

const AnalyticsTracker = () => {
	return <Route component={Analytics}/>
}

export default AnalyticsTracker
