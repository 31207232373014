import React from 'react'

const Message = ({ color, message }) => {

	// When there are mutliple form validation errors on a field, only display the first one.
	message = (typeof message === 'object' ? message[0] : message)

	return (
		<div className={`form__validation  ${color}`}>
			<small>{message}</small>
		</div>
	)
}

export default Message
