import React from 'react'
import Message from './Message'


export default class Wrapper extends React.Component {

	getLabelClass() {
		return this.props.classLabel
			? `form-label ${this.props.classLabel}`
			: 'form-label'
	}

	getElementClass() {
		return this.props.classElement
			? `form-input ${this.props.classElement}`
			: 'form-input'
	}

	getWrapperClass() {
		const { fieldApi } = this.props

		const { error, touched, warning, success } = fieldApi

		const base_class = this.props.className ? `${this.props.className}` : ''
		if (touched) {
			if (error) {
				return `${base_class} has-error`
			}
			if (warning) {
				return `${base_class} has-warning`
			}
			if (success) {
				return `${base_class} has-success`
			}
		}
		return base_class
	}

	renderMessage() {
		const { fieldApi } = this.props
		const { error, touched, warning, success } = fieldApi



		if (touched && error) {
			return (<Message color="" message={error} />)
		}
		if (touched && !error && warning) {
			return (<Message color="orange" message={warning} />)
		}
		if (touched && !error && !warning && success) {
			return (<Message color="green" message={success} />)
		}
		return null
	}
}
