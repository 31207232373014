
import React, { Component } from 'react'
import { hot } from 'react-hot-loader'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Details from './components/Details'

import {
	AnalyticsTracker,
	Header,
	Footer
} from './components'

import {GoogleTag} from '@customd/cd-analytics-tracker'
import Config from './config'

import './styles/App.scss'

import './styles/components/Welcome.scss'

import Loadable from 'react-loadable'

const Loading = (props) => {
	return(   <div>
		<p>Loading...</p>
	</div>
	)
}

const Home = Loadable({
	loader: () => import('./components/Home'),
	loading: Loading,
	delay: 300, // 0.3 seconds
})

const NotFound = Loadable({
	loader: () => import('./components/Errors/NotFound'),
	loading: Loading,
	delay: 300, // 0.3 seconds
})


const About = Loadable({
	loader: () => import('./components/About.js'),
	loading: Loading,
	delay: 300, // 0.3 seconds
})

const Listings = Loadable({
	loader: () => import('./components/Listings.js'),
	loading: Loading,
	delay: 300, // 0.3 seconds
})

// const Details = Loadable({
// 	loader: () => import('./components/Details.js'),
// 	loading: Loading,
// 	delay: 300, // 0.3 seconds
// })



class Routes extends Component {
	render() {
		return (
			<BrowserRouter>

				<div className="app__wrapper mdl-layout mdl-js-layout mdl-layout--fixed-header">
					<AnalyticsTracker />
					<GoogleTag options={{trackingId: Config.googleTagCode}} />
					<Header />
					<Switch>
						<Route exact path="/" component={Home} />
						<Route exact path="/featured/:subcat" component={Home} />

						<Route exact path="/about-us-and-auckland-commercial-office-space" component={About}/>

						<Route exact path="/auckland-property-vacancies-and-rentals/:strCategory" component={Listings} />
						<Route exact path="/auckland-property-vacancies-and-rentals/:strCategory/:subcat" component={Listings} />

						<Route exact path="/auckland-properties-for-sale/:strCategory" component={Listings} />
						<Route exact path="/auckland-properties-for-sale/:strCategory/:subcat" component={Listings} />

						<Route exact path="/auckland-properties-for-sale" component={Listings} />
						<Route exact path="/auckland-properties-for-sale/:subcat" component={Listings} />

						<Route exact path="/auckland-land-yards-for-lease-and-sale" component={Listings} />
						<Route exact path="/auckland-land-yards-for-lease-and-sale/:subcat" component={Listings} />

						<Route exact path="/auckland-properties-for-sale-and-lease/search" component={Listings} />
						<Route exact path="/auckland-properties-for-sale-and-lease/search/:subcat" component={Listings} />

						<Route exact path="/auckland-property-for-sale-and-lease/:strDescription/:strUID" component={Details} />
						<Route component={NotFound} />

					</Switch>
					<Footer />
				</div>
			</BrowserRouter>
		)
	}
}


export default hot(module)(Routes)
