import { combineReducers } from 'redux'

import session, * as fromSession from  './session'

const rootReducer = combineReducers({
	session
})

// Session information
export const getIsLoggedIn = (state) => fromSession.getIsLoggedIn(state.session)
export const getSessionUserId = (state) => fromSession.getSessionUserId(state.session)
export const getSessionUserdata = (state) => fromSession.getSessionUserdata(state.session)

export default rootReducer
