import axiosPackage from 'axios'

const axios = axiosPackage.create({
  withCredentials: true,
})

axios.interceptors.request.use(
	async (config) => {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
    config.headers = headers
    config.baseURL = `${process.env.REACT_APP_API_URL}/api`
    return config
  },
  (error) =>
    // console.log("AXIOS Request Error");
     Promise.reject(error)

)

axios.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      axiosPackage.isCancel(error) ? 'Response was cancelled' : (error?.response?.data) || 'Something went wrong'
    )
)

export default axios
