import React from 'react'
import Wrapper from './Wrapper'

export default class FormCheckbox extends Wrapper {
	render() {
		const { classElement, fieldApi,  onChange, onBlur, label, className, type, value, ...rest } = this.props

		const { setValue, setTouched } = fieldApi


		return (
			<div className={this.getWrapperClass()}>

				<label htmlFor={this.props.id} className={this.getLabelClass()}>



					<input
						className={this.getElementClass()}
						checked={!!fieldApi.value}
						type="checkbox"
						onChange={e => {
							setValue(e.target.checked)
							if (onChange) {
								onChange(e.target.checked, e)
							}
						}}
						onBlur={e => {
							setTouched()
							if (onBlur) {
								onBlur(e)
							}
						}}
						{...rest}
					/>

					<span>{this.props.label}</span>


				</label>
				{this.renderMessage()}
			</div>
		)
	}
}
