
import React from 'react'
import PropTypes from 'prop-types'
import { hot } from 'react-hot-loader'
import { Input} from './Form'

const required = field => !field || field.trim() === '' ? 'This is a required field' : null

class FormShare extends React.Component {

	static propTypes = {
		email 				: PropTypes.string,
		recipients_email	: PropTypes.string
	}

	render(){

		const {submitting, submitForm} = this.props

		return (

			<form className="form share_modal__form" onSubmit={submitForm} >

				<div className="share_modal__wrapper">

					<Input
						className="share_modal__field"
						classElement="mdl-textfield mdl-js-textfield form__input mdl-textfield__input"
						label="YOUR EMAIL"
						field="email"
						type="email"
						id="email"
						validate={required}
						placeholder="" />

					<Input
						className="share_modal__field"
						classElement="mdl-textfield mdl-js-textfield form__input mdl-textfield__input"
						label="RECIPIENTS EMAIL"
						field="recipients_email"
						type="recipients_email"
						id="recipients_email"
						validate={required}
						placeholder="" />

				</div>

				<div className="share_modal__button">

					{submitting  ? (
						<button  disabled type="submit" className="button mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect">
								Sending ...
						</button>
					) : (
						<button  type="submit" className="button mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect">
								Send
						</button>
					)}
				</div>

			</form>
		)
	}
}

export default hot(module)(FormShare)
