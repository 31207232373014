import React, { Component } from 'react'

import axios from '../api/axios'
import '../styles/components/Footer.scss'

// import { Input } from './Form'

import instagram from '../assets/images/instagram.png'
import linkedIn from '../assets/images/linkedin.png'

// const required = field => !field || field.trim() === '' ? 'This is a required field' : null

// const ContactForm = (formApi) => (
// 	<form className = "subscribe app-subscribe-box mdl-grid contactus content-block" onSubmit={formApi.submitForm} >

// 		<div className="mdl-cell mdl-cell--2-col mdl-cell--8-col-tablet mdl-cell--4-col-phone ">
// 			<Input
// 				className="mdl-textfield mdl-js-textfield"
// 				classElement="form__input mdl-textfield__input"
// 				field="firstname"
// 				type="text"
// 				id="firstname"
// 				validate={required}
// 				placeholder="First Name" />
// 		</div>
// 		<div className="mdl-cell mdl-cell--2-col mdl-cell--8-col-tablet mdl-cell--4-col-phone ">


// 			<Input
// 				className="mdl-textfield mdl-js-textfield"
// 				classElement="form__input mdl-textfield__input"
// 				field="lastname"
// 				type="text"
// 				validate={required}
// 				id="lastname"
// 				placeholder="Last Name"/>


// 		</div>

// 		<div className="mdl-cell mdl-cell--6-col mdl-cell--8-col-tablet mdl-cell--4-col-phone ">

// 			<Input
// 				className="mdl-textfield mdl-js-textfield"
// 				classElement="form__input mdl-textfield__input"
// 				field="email"
// 				type="email"
// 				id="email"
// 				validate={required}
// 				placeholder="Email Address"/>

// 		</div>

// 		<div className="mdl-cell mdl-cell--2-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">
// 			<button type="submit" className="button fullwidth__button mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect">
//                         Subscribe
// 			</button>
// 		</div>



// 	</form>
// )



class Footer extends Component {

	state = {
		formSubmitted:false,
		formError:false
	}


	handleSubmit(values){

		values.message = `Subscription from page: ${location.pathname}`
		values.type = 'Newsletter Subscription'
		values.subscribe = '1'

		const self = this

		self.setState({formError:false})

		axios
      .post('/contact', values)
      .then((res) => {
        this.setState({ formSubmitted: true })

        setTimeout(() => {
          self.setState({ formSubmitted: false })
        }, 5000)
      })
      .catch((err) => {
        console.log(err)
        self.setState({ formError: err.errors })
      })


	}


	render() {
		// const {formError} = this.state

		// const errorDisplay = formError ? (
		// 	<div className="form__error">{formError.map((err, idx) => (<span key={idx}>{err}</span>))}</div>
		// ):''

		return (
			<footer className="footer app-footer">
				{/* <div className="footer--top-section">

					<h3 className="section__title footer__title">
						Subscribe to the JKGL newsletter
					</h3>
					{errorDisplay}

					{formSubmitted ? (
						<div className="form__success">
							<span class="form__success--message">Subsription Success<i class="material-icons">done</i></span>
						</div>
					) : (

						<CaptchaForm
							siteKey={Config.googleRecaptchSiteKey}
							onSubmit={::this.handleSubmit}>
							<ContactForm />
						</CaptchaForm>

					)}


				</div> */}

				<div className="footer--bottom-section">
					<p className="mdl-typography--font-light content-block" style={{width: '100%'}}>
						<a className="social__link" href="https://www.linkedin.com/company/james-kirkpatrick-group-ltd" target="_blank" aria-label="LinkedIn">
							<img src={linkedIn} />
						</a>
						<a className="social__link" href="https://www.instagram.com/jameskirkpatrickgroup/?hl=en" target="_blank" aria-label="Instagram">
							<img src={instagram} />
						</a>
						<span>Copyright </span>&copy; 2019 JKGL. All rights reserved.<p>Disclaimer: All areas and rentals quoted are approximate and subject to final measure and confirmation by JKGL management.</p>
					</p>
				</div>
			</footer>
		)
	}
}

export default Footer
