import React, {Component, useCallback, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {NavLink} from 'react-router-dom'
import MaterialIcon from 'react-google-material-icons'
import '../styles/components/MainNav.scss'

import jamesKirkPatrick from '../assets/images/james-kirk-patrick.png'

function MainNav() {
	const [mobileMenuClass, setMobileMenuClass] = useState('')
	const [className, setClassName] = useState('')

	const mobileNavRef = useRef()

	useEffect(() => {
		const handleScroll = (event) => {
			let docx = document.documentElement
			let topx = (window.pageYOffset || docx.scrollTop)  - (docx.clientTop || 0)
			const className = topx > 120 ? 'is-casting-shadow is-compact' : ''
			setClassName(className)
		}
		const handleClickOutside = () => {
			if (mobileMenuClass) {
				setTimeout(() => setMobileMenuClass(''), 100)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		window.addEventListener('scroll', handleScroll)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
			window.removeEventListener('scroll', handleScroll)
		}
	}, [mobileNavRef.current, mobileMenuClass])


	const mobileMenu = useCallback((event) => {
		event.preventDefault()
		setMobileMenuClass(prev => prev === 'is-visible' ? '' : 'is-visible')
	}, [])

		return (
			<div className={className}>
				<header
					className={`header fullwidth__button app-header mdl-layout__header mdl-layout__header--waterfall mdl-layout__header--scroll__ __ ${className}`}>
					<div className="main_nav">
						<span className="main_nav__title app-title mdl-layout-title">
							<NavLink exact={false} to="/" title="James Kirkpatrick Group Limited">
								<img
									className="app-logo-image main_nav__logo"
									src={jamesKirkPatrick}
									alt="James Kirkpatrick Group Limited"/>
							</NavLink>
						</span>

						<div className="main_nav__container">
							<nav className="app-navigation mdl-navigation">
								<NavItem
									className="mdl-navigation__link mdl-typography--text-uppercase"
									link="/auckland-property-vacancies-and-rentals/warehouses"
									title="Auckland Warehouses for lease and sale"
									label="Warehouse"
									exact/>
								<NavItem
									className="mdl-navigation__link mdl-typography--text-uppercase"
									link="/auckland-property-vacancies-and-rentals/offices"
									title="Auckland Offices for lease and sale"
									label="Office"
									exact/>
								<NavItem
									className="mdl-navigation__link mdl-typography--text-uppercase"
									link="/auckland-property-vacancies-and-rentals/retail-show-rooms"
									title="Auckland Retail Space for lease and sale"
									label="Retail"
									exact/>
								<NavItem
									className="mdl-navigation__link mdl-typography--text-uppercase"
									link="/auckland-properties-for-sale/new-developments"
									title="Auckland Developments for lease and sale"
									label="Developments"
									exact/>
								<NavItem
									className="mdl-navigation__link mdl-typography--text-uppercase"
									link="/auckland-properties-for-sale/residential"
									title="Auckland Residential for lease and sale"
									label="Residential"
									exact/>
								<NavItem
									className="mdl-navigation__link mdl-typography--text-uppercase"
									link="/auckland-properties-for-sale"
									title="Auckland Properties for lease and sale"
									label="Properties for Sale"
									exact/>
								<NavItem
									className="mdl-navigation__link mdl-typography--text-uppercase"
									link="/auckland-land-yards-for-lease-and-sale"
									title="Auckland Retail Space for lease and sale"
									label="Land Yards"
									exact/>
								<NavItem
									className="mdl-navigation__link mdl-typography--text-uppercase"
									link="/about-us-and-auckland-commercial-office-space"
									title="About Us — Auckland Commercial property for lease and sale"
									label="About Us"
									exact/>
							</nav>
						</div>

						<a href="#" onClick={mobileMenu} tabIndex="0" className="main_nav__menu" ref={mobileNavRef}>
							<MaterialIcon icon="menu" size={24}/>
						</a>

					</div>
				</header>
				<div className={`app-drawer mdl-layout__drawer ${mobileMenuClass}`}>
					<nav className="mdl-navigation">
						<NavItem
							className="mdl-navigation__link mdl-typography--text-uppercase"
							link="/auckland-property-vacancies-and-rentals/warehouses"
							title="Auckland Warehouses for lease and sale"
							label="Warehouse"
							exact/>
						<NavItem
							className="mdl-navigation__link mdl-typography--text-uppercase"
							link="/auckland-property-vacancies-and-rentals/offices"
							title="Auckland Offices for lease and sale"
							label="Office"
							exact/>
						<NavItem
							className="mdl-navigation__link mdl-typography--text-uppercase"
							link="/auckland-property-vacancies-and-rentals/retail-show-rooms"
							title="Auckland Retail Space for lease and sale"
							label="Retail"
							exact/>
						<NavItem
							className="mdl-navigation__link mdl-typography--text-uppercase"
							link="/auckland-properties-for-sale/new-developments"
							title="Auckland Developments for lease and sale"
							label="Developments"
							exact/>
						<NavItem
							className="mdl-navigation__link mdl-typography--text-uppercase"
							link="/auckland-properties-for-sale/residential"
							title="Auckland Residential for lease and sale"
							label="Residential"
							exact/>
						<NavItem
							className="mdl-navigation__link mdl-typography--text-uppercase"
							link="/auckland-properties-for-sale"
							title="Auckland Properties for lease and sale"
							label="Properties for Sale"
							exact/>
						<NavItem
							className="mdl-navigation__link mdl-typography--text-uppercase"
							link="/auckland-land-yards-for-lease-and-sale"
							title="Auckland Retail Space for lease and sale"
							label="Land Yards"
							exact/>
						<NavItem
							className="mdl-navigation__link mdl-typography--text-uppercase"
							link="/about-us-and-auckland-commercial-office-space"
							title="About Us — Auckland Commercial property for lease and sale"
							label="About Us"
							exact/>
					</nav>
				</div>
			</div>
		)
}

class NavItem extends Component {

	static propTypes = {
		label: PropTypes.string.isRequired,
		link: PropTypes.string.isRequired,
		exact: PropTypes.bool,
		login: PropTypes.bool,
		router: PropTypes.bool
	}

	static defaultProps = {
		exact: false,
		login: false,
		router: true
	}

	renderLink() {

		const {label, link, exact,  router} = this.props

		if (router) {
			return <NavLink
				exact={exact}
				to={link}
				className="main_nav__link"
				activeClassName="mdl-navigation__link--active">{label}</NavLink>
		}

		return <a href={link} className="mdl-typography--text-uppercase">{label}</a>
	}

	render() {

		return (
			<li className="main_nav__item">
				{this.renderLink()}
			</li>
		)
	}
}

export default MainNav
