import React, { Component } from 'react'

import MainNav from './MainNav'

class Header extends Component {

	render() {
		return (
			<MainNav/>
		)
	}
}

export default Header
