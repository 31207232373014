import { Form, withField } from 'react-form'
import FormInput from './Input'
import FormCheckbox from './Checkbox'
import Message from './Message'
import FormTextarea from './Textarea'
import CaptchaForm from './CaptchaForm'

const Textarea = withField(FormTextarea)
const Input = withField(FormInput)
const Checkbox = withField(FormCheckbox)

export {
	Form,
	CaptchaForm,
	Input,
	Textarea,
	Checkbox,
	Message
}
