
import React from 'react'
import PropTypes from 'prop-types'
import { hot } from 'react-hot-loader'

import { Input, Textarea} from './Form'

const required = field => !field || field.trim() === '' ? 'This is a required field' : null

const displayError = (error) =>
	!error
		? null
		:	<div className="form__error">
				<span >{error}</span>
			</div>

class FormContact extends React.Component {

	static propTypes = {
		firstname		: PropTypes.string,
		lastname		: PropTypes.string,
		email 			: PropTypes.string,
		phone			: PropTypes.string,
		em 				: PropTypes.string
	}

	render(){

		const {submitting, submitForm, errors} = this.props

		return (

			<form className = "form about__form" onSubmit={submitForm} >
				{displayError(errors.firstname)}
				<Input
					className="mdl-textfield mdl-js-textfield"
					classElement="form__input mdl-textfield__input"
					field="firstname"
					type="text"
					id="firstname"
					validate={required}
					placeholder="First Name" />
				{displayError(errors.lastname)}

				<Input
					className="mdl-textfield mdl-js-textfield"
					classElement="form__input mdl-textfield__input"
					field="lastname"
					type="text"
					validate={required}
					id="lastname"
					placeholder="Last Name"/>
				{displayError(errors.email)}

				<Input
					className="mdl-textfield mdl-js-textfield"
					classElement="form__input mdl-textfield__input"
					field="email"
					type="email"
					id="email"
					required="This is required"
					email="Please enter a valid email"
					placeholder="Email"/>
				{displayError(errors.phone)}

				<Input
					className="mdl-textfield mdl-js-textfield"
					classElement="form__input mdl-textfield__input"
					field="phone"
					type="phone"
					id="phone"
					validate={required}
					placeholder="Phone"/>
				{displayError(errors.em)}

				<Textarea
					className="mdl-textfield mdl-js-textfield"
					classElement="form__input form__textarea mdl-textfield__input"
					field="em"
					id="mess"
					placeholder="Leave us a message"
					validate={required} />

				{/* { <Checkbox label="Sign up for newsletter" value="agree" type="checkbox" id="signup" field="signup" classElement="mdl-checkbox__input" className="mdl-checkbox mdl-js-checkbox" /> } */}

				{submitting ? (
					<button  disabled type="submit" className="button mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect">
						Submitting ...
					</button>
				) : (
					<button  type="submit" className="button mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect">
						Submit
					</button>
				)}

			</form>
		)
	}
}

export default hot(module)(FormContact)
